<template>
  <div class="page">
    <div class="page__content-wrapper office">
      <div class="page__full-width office-full">
        <div class="page__min-container ">
          <div class="text__head">
            <div class="top-border"></div>
            Бланк А4
          </div>
          <div class="page__img-wrapper">
            <img src="~assets/img/office-page/office-1.png" alt="">
          </div>
        </div>
        <div class="page__link-block download-block">
          <a :href="blank.url"
             @click.prevent="downloadItemZip(blank)"
             class="btn__circle btn__nav-slider  btn-download">
            <img src="~assets/img/icons/icon-doc.svg" alt="doc">
          </a>
        </div>
      </div>
      <div class="page__full-width office-full office-1" style="margin-bottom: 92px;">
        <div class="page__min-container">
          <div class="text__head">
            <div class="top-border"></div>
            Визитная карточка
          </div>
          <div class="text__normal">
            Матовый дизайнерский картон, 300 г/м<sup>2</sup> <br>
            Шелкография <br>
            Вырубка <br>
            Матовый УФ-лак на логотипе на оборотной стороне
          </div>
          <div class="page__img-wrapper">
            <img src="~assets/img/office-page/40222-plastic-cards-mockup 1.png" alt="">
          </div>
        </div>
        <div class="page__link-block download-block">
          <a :href="card.url"
             @click.prevent="downloadItemZip(card)"
             class="btn__circle btn__nav-slider  btn-download">
            <img src="~assets/img/icons/icon-ai.svg" alt="AI">
          </a>
        </div>
      </div>
      <div class="page__full-width office-full badge-block">
        <div class="page__min-container">
          <div class="text__head">
            <div class="top-border"></div>
            Бейдж
          </div>
          <div class="text__normal">
            Цифровая полноцветная печать <br>
            Плотность бумаги 200–250 г/м<sup>2</sup> <br>
            Ленточка фирменного цвета Sapphire Blue
          </div>
          <div class="page__img-wrapper">
            <img src="~assets/img/office-page/office-3.png" alt="">
          </div>
        </div>
        <div class="page__link-block download-block">
          <a :href="badge.url"
             @click.prevent="downloadItemZip(badge)"
             class="btn__circle btn__nav-slider  btn-download">
            <img src="~assets/img/icons/icon-ai.svg" alt="AI">
          </a>
        </div>
      </div>
      <div class="page__full-width office-full envelope-block">
        <div class="page__min-container">
          <div class="text__head">
            <div class="top-border"></div>
            Конверт C4
          </div>
          <div class="page__img-wrapper">
            <img src="~assets/img/office-page/office-4.png" alt="">
          </div>
        </div>
        <div class="page__link-block download-block">
          <a :href="envelope.url"
             @click.prevent="downloadItemZip(envelope)"
             class="btn__circle btn__nav-slider  btn-download">
            <img src="~assets/img/icons/icon-ai.svg" alt="AI">
          </a>
        </div>
      </div>
      <div class="page__full-width office-full folder-block">
        <div class="page__min-container">
          <div class="text__head">
            <div class="top-border"></div>
            Папка
          </div>
          <div class="text__normal">
            Материал: экокожа <br>
            Цифровая печать
          </div>
          <div class="page__img-wrapper">
            <img src="~assets/img/office-page/office-5.png" alt="">
          </div>
        </div>
        <div class="page__link-block download-block">
          <a :href="folder.url"
             @click.prevent="downloadItemZip(folder)"
             class="btn__circle btn__nav-slider  btn-download">
            <img src="~assets/img/icons/icon-ai.svg" alt="AI">
          </a>
        </div>
      </div>
      <div class="page__download-block ">
        <a class="main-btn_download"
           :href="all.url"
           @click.prevent="downloadItemZip(all)">
          <svg style="margin-right: 8px;" width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="16" stroke="white" stroke-linecap="round" stroke-dasharray="0.1 4"/>
            <path d="M23.6464 26.3536C23.8417 26.5488 24.1583 26.5488 24.3536 26.3536L27.5355 23.1716C27.7308 22.9763 27.7308 22.6597 27.5355 22.4645C27.3403 22.2692 27.0237 22.2692 26.8284 22.4645L24 25.2929L21.1716 22.4645C20.9763 22.2692 20.6597 22.2692 20.4645 22.4645C20.2692 22.6597 20.2692 22.9763 20.4645 23.1716L23.6464 26.3536ZM24.5 18L24.5 17.5L23.5 17.5L23.5 18L24.5 18ZM24.5 26L24.5 18L23.5 18L23.5 26L24.5 26Z" fill="white"/>
            <path d="M31 27V29C31 29.5523 30.5523 30 30 30H18C17.4477 30 17 29.5523 17 29V27" stroke="white" stroke-linecap="square"/>
          </svg>
          Скачать все макеты
        </a>
      </div>
    </div>


    <div class="page-nav__tablet">
      <router-link class="next-page__link" to="/identity/photo-style">
        <img src="~assets/img/icons/row-grey-small.svg" alt="prev">
        Фотостиль
      </router-link>
      <router-link class="next-page__link" to="/brand/letters-and-mailings">
        Письма и рассылки
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
    <div class="next-page">
      <router-link class="next-page__link" to="/brand/letters-and-mailings">
        Письма и рассылки
        <img src="~assets/img/icons/row-grey-small.svg" alt="next">
      </router-link>
    </div>
  </div>
</template>
<script>

import Axios from "axios";

export default {
  methods: {
    downloadItemZip({urlZip, label}) {
      Axios.get(urlZip, {responseType: 'blob'})
          .then(response => {
            const blob = new Blob([response.data], {type: 'application/zip'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(console.error)
    },
  },
  data() {
    return {
      blank: {
        url: '../dowload-file/Brand--Office/office-page/GDP-Blank.zip',
        urlZip: '../dowload-file/Brand--Office/office-page/GDP-Blank.zip',
        label: "GDP-Blank"
      },
      card: {
        url: '../dowload-file/Brand--Office/office-page/GDP-Card.zip',
        urlZip: '../dowload-file/Brand--Office/office-page/GDP-Card.zip',
        label: "GDP-Card"
      },
      badge: {
        url: '../dowload-file/Brand--Office/office-page/GDP-Badge.zip',
        urlZip: '../dowload-file/Brand--Office/office-page/GDP-Badge.zip',
        label: "GDP-Badge"
      },
      envelope: {
        url: '../dowload-file/Brand--Office/office-page/GDP-Envelope.zip',
        urlZip: '../dowload-file/Brand--Office/office-page/GDP-Envelope.zip',
        label: "GDP-Envelope"
      },
      folder: {
        url: '../dowload-file/Brand--Office/office-page/GDP-Folder.zip',
        urlZip: '../dowload-file/Brand--Office/office-page/GDP-Folder.zip',
        label: "GDP-Folder"
      },
      all: {
        url: '../dowload-file/Brand--Office/office-page/Office.zip',
        urlZip: '../dowload-file/Brand--Office/office-page/Office.zip',
        label: "Office"
      },
    }
  }
}
</script>
<style lang="scss" scoped>
@import "src/assets/style/page-block";
@import "src/assets/style/text-style";
@import "src/assets/style/buttons";

.page {
  margin-bottom: 192px;

  .page__full-width {
    .page__min-container {
      margin-bottom: 0;
      .text__head {
        font-weight: 500;
      }
    }
    &:first-child {
      .page__min-container {
        margin-bottom: 0;

        .text__head {
          margin-bottom: -1px;
        }

        .page__img-wrapper {
          margin-bottom: 92px;
        }
      }

      .page__link-block {
        margin-left: 20px;
      }
    }

    &:nth-child(2) {
      .page__min-container {

        .text__head {
          margin-bottom: 5px;
        }

        .text__normal {
          margin-bottom: 48px;
        }
      }

      .page__link-block {
        margin-left: 20px;
      }
    }

    &:nth-child(3) {
      .page__min-container {
        margin-bottom: 92px;

        .text__head {
          margin-bottom: 11px;
        }

        .text__normal {
          margin-bottom: 2px;
          line-height: 21px;
        }

        .page__img-wrapper {
          margin-bottom: 0;
        }
      }

      .page__link-block {
        padding-top: 167px;
        margin-left: 20px;
      }
    }

    &:nth-child(4) {
      .page__min-container {
        margin-bottom: 92px;

        .text__head {
          margin-bottom: 0;
        }
      }

      .page__link-block {
        padding-top: 143px;
        margin-left: 20px;
      }

      .page__img-wrapper {
        margin-bottom: 0;
      }
    }

    &:nth-child(5) {
      .page__min-container {
        .text__head {
          margin-bottom: 9px;
        }
        .text__normal {
          margin-bottom: 0;
        }
        .page__img-wrapper {
          margin-bottom: 91px;
        }
      }
      .page__link-block {
        margin-left: 20px;
        padding-top: 174px;
      }
    }
  }
  .main-btn_download{
    letter-spacing: 0.85px;
    font-weight: 700;
    font-family: 'formular',serif;
  }
}

sup {
  font-size: 10px;
}

.next-page {
  margin-top: 80px;
}

.badgekeeper {
  &-block {
    position: relative;
    display: flex;
  }

  &__info {
    max-width: 832px;
  }

  &__img-wrap {
    max-width: 832px;
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__title {
    position: absolute;
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #39434D;
    top: 38%;
  }

  &__download-block {
    display: flex;
    align-items: center;
  }
}

.office-full.folder-block {
  .page__link-block {
    padding-top: 176px;
  }
}

.office-full.badge-block {
  .page__link-block {
    padding-top: 176px;
  }
}

.office-full.envelope-block {
  .page__link-block {
    padding-top: 145px;
  }
}

.pc__download-block {
  display: flex;
  margin-left: 30px;
  margin-top: 254px;
}

.pc__block {
  display: flex;
  position: relative;
}

.pc__block__title {
  position: absolute;
  z-index: 1;
  left: 164px;
}

.badgekeeper__title {
  font-weight: bold;
}

.badgekeeper__download-block {
  margin-left: 20px;

  .btn-download {
    position: relative;
    top: -40px;
  }
}

.office-full {
  display: flex;
}

.office-1 {
  margin-bottom: 0;

  .page__min-container {
    margin-bottom: 0;
  }

  .page__img-wrapper {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 204px;
    &__full-width {
      width: 100%;
    }
  }
}

@media screen  and (max-width: 1246px){
  .page__download-block{
    display: none;
  }
}


@media screen and (max-width: 1216px) {
  .office-item .office-item__link-block,
  .pc__download-block,
  .badgekeeper__download-block,
  .office-item__link-block {
    display: none !important;
  }
  .office-item__link-block {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .pc__block__title {
    left: 56px;
  }
}

@media screen and (max-width: 700px) {
  .pc__block__title {
    left: 40px;
    top: -20px;
  }
  .btn__gradient-main.btn__left-icon {
    display: none;
  }
  .badgekeeper__title {
    top: -32px;
  }
}
</style>
